<template>

  <v-app id="inspire">
    <v-app id="inspire">
      <v-main>
        <v-container
          class="fill-height"
          fluid
        >
          <v-row
            align="center"
            justify="center"
          >
            <v-col
              cols="12"
              sm="8"
              md="4"
            >
              <v-card class="elevation-12">
                <v-toolbar
                  color="primary"
                  dark
                  flat
                >
                  <v-toolbar-title>Informe seu email</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <v-form v-model="valid" ref="form">
                    <v-text-field
                      label="Login"
                      name="login"
                      prepend-icon="mdi-account"
                      type="text"
                      v-model="$store.state.user.email"
                      :rules="ruleEmail"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <!-- <v-spacer></v-spacer> -->
                  <v-btn color="primary" @click="send">Enviar</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
    <v-snackbar
      v-model="$store.state.alert1"
      :timeout="$store.state.alert1Timeout"
      top
    >
      {{ $store.state.alert1Message }}
      <v-icon :color="$store.state.alert1Color">{{
        $store.state.alert1Icon
      }}</v-icon>
    </v-snackbar>
  </v-app>

</template>

<script>
import { forgotPassword } from '../../services/users'
  export default {
    data:()=>({
      valid: false,
      message: null,
      rulePassword: [true],
      ruleEmail: [value => value.length > 0 || 'Email é obrigatório', value => value.includes('@') || 'Email inválido']
    }),
    methods: {
      send(){
         this.$refs.form.validate()
         if(!this.valid) return
         this.$store.state.loading = true
        forgotPassword(this.$store.state.user.email)
        .then((data) => {
          this.$store.state.loading = false
          window.location.href = "/login"
          this.$store.commit('success', 'Email enviado com sucesso, em breve receberá um email para alterar sua senha!')
          this.message = data
        })
        .catch(err => {
          setTimeout(()=>this.$store.state.loading = false, 5000)
          this.$store.commit('failed', 'Falha ao tentar enviar email, verifique o email digitado ou tente mais tarde')
          this.message = err
        })
      }
    },
    mounted(){
      localStorage.removeItem('token')
    }
  }
</script>

<style lang="scss" scoped>

</style>